import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Meta from "../components/meta"
import Moment from "react-moment"
import "moment/locale/ru"
import PagesHeader from "../components/pagesHeader/pagesHeader"
import { Layout, Row, Col, Breadcrumb } from "antd"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  return (
    <Meta
      title={frontmatter.title}
      date={frontmatter.date}
      noindex={frontmatter.noindex}
      description={frontmatter.description}
    >
      <Layout.Content>
        <PagesHeader />

        <Row
          className="container print-container"
          style={{ marginTop: 96, marginBottom: 96 }}
        >
          <Col>
            <Breadcrumb style={{ marginBottom: 32 }} className="print-hide">
              <Breadcrumb.Item>
                <Link to="/">Главная</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{frontmatter.title}</Breadcrumb.Item>
            </Breadcrumb>

            <div dangerouslySetInnerHTML={{ __html: html }} />
            <p style={{ marginTop: "1em" }} className="align-right">
              Опубликовано: &nbsp;
              <time dateTime={frontmatter.date}>
                {<Moment date={frontmatter.date} format="ll" locale="ru" />}
              </time>
            </p>
          </Col>
        </Row>
      </Layout.Content>
    </Meta>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        slug
        title
        noindex
        description
      }
    }
  }
`

Template.propTypes = {
  data: PropTypes.any.isRequired,
}
