import React from "react"
import { Link } from "gatsby"
import { useSiteMetadata } from "../meta"
import "./pagesHeader.less"
import { Layout } from "antd"

function PagesHeader() {
  const { title } = useSiteMetadata()

  return (
    <Layout.Header className="pages-header print-hide">
      <div className="pages-header__inner">
        <Link to="/">{title}</Link>
      </div>
    </Layout.Header>
  )
}

export default PagesHeader
